import {enUS as en, es} from 'date-fns/locale'
import isDate from 'date-fns/isDate'
import toDate from 'date-fns/toDate'
import intervalToDuration from 'date-fns/intervalToDuration'
import format from 'date-fns/format'
import _isAfter from 'date-fns/isAfter'
import _isBefore from 'date-fns/isBefore'
import parseISO from 'date-fns/parseISO'
import _formatDuration from 'date-fns/formatDuration'
import _isWithinInterval from 'date-fns/isWithinInterval'
import add from 'date-fns/add'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import endOfDay from 'date-fns/endOfDay'
import getUnixTime from 'date-fns/getUnixTime/'
import _subSeconds from 'date-fns/subSeconds'

const locales = {en, es}

export function formatDate(value, dateFormat, language) {
  const locale = locales[language]
  return format(parseDate(value), dateFormat, {locale})
}

export function formatDuration(seconds, language) {
  const duration = intervalToDuration({start: toDate(0), end: toDate(seconds * 1000)})
  const locale = locales[language]
  return _formatDuration(duration, {locale}) 
}

export function getValue (date) {
  return getUnixTime(parseDate(date))
}

export function isAfter(date, dateToCompare) {
  return _isAfter(parseDate(date), parseDate(dateToCompare))
}
export function isBefore(date, dateToCompare) {
  return _isBefore(parseDate(date), parseDate(dateToCompare))
}

export function isWithinInterval(date, {start, end}) {
  return _isWithinInterval(parseDate(date), {start: parseDate(start), end: parseDate(end)})
}

export function remainingSeconds(started, seconds) {
  const duration = intervalToDuration({start: toDate(0), end: toDate(seconds * 1000)})
  return differenceInSeconds(add(parseDate(started), duration), new Date())
}

export function secondsToDate(target) {
  return differenceInSeconds(parseDate(target), new Date())
}

export function parseDate(value) {
  if (isDate(value)) return value
  switch(typeof(value)) {
    case 'number': return toDate(value);
    case 'string': return parseISO(value);
    default: return false;
  }
}

export function subSeconds(value, amount) {
  return _subSeconds(parseDate(value), amount)
}

export {format, parseISO, locales, endOfDay, isDate}
