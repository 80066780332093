import update, {extend} from 'immutability-helper'

extend('$shallow', function(value, object) {
  const commands = Object.entries(value).reduce((obj, [key, value]) => ({...obj, [key]: {$set: value}}), {})
  return update(object, commands)
})

extend('$auto', function(value, object) {
  return object ?
    update(object, value):
    update({}, value);
})

extend('$autoArray', function(value, object) {
  return object ?
    update(object, value):
    update([], value);
})

export function createLoadableList (override) {
  return {loaded: false, items: [], refreshes:0, ...(override || {})}
}

extend('$refreshLoadableList', (value, object) => {
  return update(object, {refreshes: {$apply: i => i + 1}})
})

extend('$initLoading', (value, object) => {
  return update(object, {loading: {$set: true}, items: {$set: []}})
})

extend('$startLoading', (value, object) => {
  return update(object, {loading: {$set: true}})
})

extend('$updateLoadableList', (value, object) => {
  return update(object, {loading: {$set: false}, items: {$set: value}})
})

export {update as default} 
